// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Check if an authentication token is present in localStorage
const isAuthenticatedInLocalStorage = !!localStorage.getItem("authToken");

const initialState = {
  isAuthenticated: isAuthenticatedInLocalStorage, // Set initial state based on localStorage
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.clear();
      toast.success("Logout successfully");
      window.location.pathname = "/";
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
