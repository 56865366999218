import React from "react";
import HomeBanner from "../components/hombanner";
import Homesection from "../components/homesection";

import Layout from "../layout/layout";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../Graphql/Queries";
import Loader from "../components/Loader";

const Home = () => {
  const { data, loading } = useQuery(GET_CATEGORIES);
  console.log(data?.categories);
  return (
    <>
      {loading ? <Loader /> : null}
      <Layout>
        <HomeBanner categories={data?.categories} loading={loading} />
        <Homesection categories={data?.categories} loading={loading} />
      </Layout>
    </>
  );
};

export default Home;
