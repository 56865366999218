import { gql } from "@apollo/client";
export const CREATE_USER = gql`
  mutation signup(
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
  ) {
    signup(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
    ) {
      token
    }
  }
`;
export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        profile_image
        cv
        phone
        last_name
        job_title
        id
        first_name
        email
        cover_image
        role {
          name
          id
        }
      }
    }
  }
`;
export const CONTACT_US = gql`
  mutation contactUs(
    $email: String!
    $name: String!
    $subject: String!
    $comments: String!
  ) {
    contactUs(email: $email, name: $name, subject: $subject, comment: $comments)
  }
`;
export const CHANGE_PASSWORAD = gql`
  mutation passwordUpdate(
    $current_password: String!
    $password: String!
    $password_confirmation: String!
  ) {
    passwordUpdate(
      current_password: $current_password
      password: $password
      password_confirmation: $password_confirmation
    )
  }
`;
