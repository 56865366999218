import React, { useState } from "react";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const HomeBanner = ({ categories: data, loading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedCategories, setDisplayedCategories] = useState([]);

  if (!data) {
    // Handle loading or error state
    return <Loader />;
  }

  const filteredCategories = (value) =>
    data?.filter((category) =>
      category?.name?.toLowerCase()?.includes(value.toLowerCase())
    );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() !== "") {
      const filtered = filteredCategories(value);
      setDisplayedCategories(filtered);
    } else {
      setDisplayedCategories([]);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section
        className="py-36 lg:py-72 lg:pb-0 md:pb-8 w-full table relative bg-[url('../../assets/images/hero/bg3.html')] bg-center bg-cover"
        id="home"
      >
        <div className="absolute inset-0 bg-costom" />
        <div className="container relative">
          <div className="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-[30px]">
            <div className="lg:col-span-7 lg:me-6">
              <h4 className="text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">
                Find a new job and <br />
                build career
              </h4>
              <p className="text-white/70 text-lg max-w-xl">
                Find Jobs, Employment &amp; Career Opportunities. Some of the
                companies we've helped recruit excellent applicants over the
                years.
              </p>
              <div className="grid grid-cols-1" id="reserve-form">
                <div className="mt-8">
                  <div className="bg-white dark:bg-slate-900 border-0 shadow rounded p-3">
                    <form action="#">
                      <div className="registration-form text-dark text-start">
                        <div className="grid md:grid-cols-12 grid-cols-1 md:gap-0 gap-6">
                          <div className="col-span-12">
                            <div className="filter-search-form relative filter-border">
                              <i className="uil uil-briefcase-alt icons" />
                              <input
                                name="name"
                                type="text"
                                id="job-keyword"
                                onChange={handleInputChange}
                                className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                placeholder="Search your Keywords"
                              />
                            </div>
                            {displayedCategories?.length > 0
                              ? displayedCategories.map((category) => (
                                  <Link to={`/JobCategories/${category.id}`}>
                                    <div
                                      key={category.id}
                                      className="group p-4 rounded-md shadow dark:shadow-gray-700 bg-slate-50 dark:bg-slate-800 hover:bg-emerald-600 dark:hover:bg-emerald-600 transition-all duration-500"
                                    >
                                      <h5 className="text-lg font-semibold dark:text-white/80 group-hover:text-white">
                                        {category.name}
                                      </h5>

                                      {/* <div className="mt-2">
                                        <div className="text-emerald-600 dark:text-white/80 group-hover:text-white font-medium">
                                          Explore Jobs{" "}
                                        </div>
                                        <i className="uil uil-arrow-right" />
                                      </div> */}
                                    </div>
                                  </Link>
                                ))
                              : searchTerm.trim() !== "" && (
                                  <div className="group p-4 rounded-md shadow dark:shadow-gray-700 bg-slate-50 dark:bg-slate-800 hover:bg-emerald-600 dark:hover:bg-emerald-600 transition-all duration-500">
                                    <h5 className="text-lg font-semibold dark:text-white/80 group-hover:text-white">
                                      No data found
                                    </h5>
                                  </div>
                                )}
                          </div>
                        </div>
                        {/*end grid*/}
                      </div>
                      {/*end container*/}
                    </form>
                  </div>
                </div>
                {/*ed col*/}
              </div>
              {/*end grid*/}
              <div className="mt-4">
                <span className="text-white/70">
                  <span className="text-white">Popular Searches :</span> Work
                  Visas, Career Growth Strategies, Continuing Education ,
                  Accommodation, Job Opportunities
                </span>
              </div>
            </div>
            <div className="lg:col-span-5 mt-8 md:mt-0">
              <div className="relative z-2">
                <div className="p-3 shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900">
                  <img
                    src="assets/images/hero.jpg"
                    className="shadow-md rounded-md"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end container*/}
      </section>
      <div className="relative">
        <div className="shape overflow-hidden text-white dark:text-slate-900 rtl:-scale-x-[1]">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
