// index.js
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use local storage as the storage engine
import { PersistGate } from "redux-persist/integration/react"; // Import the PersistGate component
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import authReducer from "./slice/authslice";
import { ToastContainer } from "react-toastify";

const persistConfig = {
  key: "root", // Key for the storage
  storage, // Use local storage as the storage engine
  whitelist: ["auth"], // Add the slices you want to persist here
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer, // Use the persisted reducer
    // Add other reducers here if needed
  },
});

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <App />
      <ToastContainer />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
