/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import Layout from "../layout/layout";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "../Graphql/Mutations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    comments: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    console.log(name, value);
  };
  const [contactUs] = useMutation(CONTACT_US);
  const handleSubmit = (e) => {
    e.preventDefault();
    contactUs({ variables: values })
      .then((result) => {
        if (result && result.data) {
          // Access the response data here
          toast.success("Thank You.Our Team Will be Contact You Soon!");
          navigate("/");
        } else {
          toast.error("SomeThing Went Wrong");
        }
      })
      .catch((errors) => {
        toast.error(errors + "");
        console.error(errors);
      });
  };
  console.log(values);
  return (
    <Layout>
      {/* Google Map */}
      {/* <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style={{ border: 0 }}
              className="w-full h-[500px]"
              allowFullScreen=""
            />
          </div>
        </div>
      </div> */}
      {/*end container*/}
      {/* Google Map */}
      {/* Start Section*/}
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-6 md:col-span-6">
              <a href="mailto:info@joblink.com" className="relative">
                <img src="assets/images/svg/contact.svg" alt="" />
              </a>
              {/* <a
                href="mailto:info@joblink.com"
                className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 absolute top-1.5/4 left-1.5/4 rotate-14"
              >
                info@joblink.com
              </a> */}
            </div>
            <div className="lg:col-span-6 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    Get in touch !
                  </h3>
                  <form name="myForm" id="myForm" onSubmit={handleSubmit}>
                    <p className="mb-0" id="error-msg" />
                    <div id="simple-msg" />
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="name" className="font-semibold">
                          Your Name:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          onChange={handleInput}
                          placeholder="Name :"
                        />
                      </div>
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="email" className="font-semibold">
                          Your Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          onChange={handleInput}
                          placeholder="Email :"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-semibold">
                          Your Subject:
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          onChange={handleInput}
                          placeholder="Subject :"
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="comments" className="font-semibold">
                          Your Comment:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          onChange={handleInput}
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2 textarea"
                          placeholder="Message :"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end container*/}
        {/* <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <i className="uil uil-phone" />
                </div>
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Phone</h5>
                <p className="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>
                <div className="mt-5">
                  <a
                    href="tel:+152534-468-854"
                    className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500"
                  >
                    +152 534-468-854
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <i className="uil uil-envelope" />
                </div>
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Email</h5>
                <p className="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>
                <div className="mt-5">
                  <a
                    href="mailto:info@joblink.com"
                    className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500"
                  >
                    info@joblink.com
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <i className="uil uil-map-marker" />
                </div>
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Location</h5>
                <p className="text-slate-400 mt-3">
                  C/54 Northwest Freeway, Suite 558, <br /> Houston, USA 485
                </p>
                <div className="mt-5">
                  <a
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500"
                  >
                    View on Google map
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Section*/}
    </Layout>
  );
};

export default Contact;
