/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Jobsection = () => {
  return (
    <section className="relative md:py-24 py-16">
      <div className="container">
        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div className="lg:col-span-4 md:col-span-6">
            <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
              <form>
                <div className="grid grid-cols-1 gap-3">
                  <div>
                    <label htmlFor="searchname" className="font-semibold">
                      Search Company
                    </label>
                    <div className="relative mt-2">
                      <i className="uil uil-search text-lg absolute top-[5px] start-3" />
                      <input
                        name="search"
                        id="searchname"
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="font-semibold">Categories</label>
                    <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                      <option value="WD">Web Designer</option>
                      <option value="WD">Web Developer</option>
                      <option value="UI">UI / UX Desinger</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-semibold">Location</label>
                    <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                      <option value="NY">New York</option>
                      <option value="MC">North Carolina</option>
                      <option value="SC">South Carolina</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-semibold">Job Types</label>
                    <div className="block mt-2">
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="fulltime"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="fulltime"
                          >
                            Full Time
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          3
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="parttime"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="parttime"
                          >
                            Part Time
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          7
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="Freelancing"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="Freelancing"
                          >
                            Freelancing
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          4
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="fixedprice"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="fixedprice"
                          >
                            Fixed Price
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          6
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="Remote"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="Remote"
                          >
                            Remote
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          7
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="hourlybasis"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="hourlybasis"
                          >
                            Hourly Basis
                          </label>
                        </div>
                        <span className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">
                          44
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="font-semibold">Salary</label>
                    <div className="block mt-2">
                      <div>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            name="radio-colors"
                            defaultValue={1}
                            defaultChecked=""
                          />
                          <span className="text-slate-400">10k - 15k</span>
                        </label>
                      </div>
                      <div>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            name="radio-colors"
                            defaultValue={1}
                          />
                          <span className="text-slate-400">15k - 25k</span>
                        </label>
                      </div>
                      <div>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            name="radio-colors"
                            defaultValue={1}
                          />
                          <span className="text-slate-400">more than 25K</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="submit"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                      defaultValue="Apply Filter"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*end col*/}
          <div className="lg:col-span-8 md:col-span-6">
            <div className="grid lg:grid-cols-2 gap-[30px]">
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/facebook-logo.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Facebook
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Full Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Web Designer / Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Australia
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/google-logo.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Google
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Part Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Marketing Director
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> USA
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/android.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Android
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Remote
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Application Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> China
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/lenovo-logo.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Lenovo
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    WFH
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Senior Product Designer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Dubai
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/spotify.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Spotify
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Full Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    C++ Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> India
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/linkedin.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Linkedin
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Remote
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Php Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Pakistan
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/circle-logo.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Circle CI
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Full Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Web Designer / Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Australia
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/skype.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Skype
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Part Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Marketing Director
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> USA
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/snapchat.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Snapchat
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Remote
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Application Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> China
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/shree-logo.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Shreethemes
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    WFH
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Senior Product Designer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Dubai
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/telegram.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Telegram
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Full Time
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    C++ Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> India
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
              <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                      <img
                        src="assets/images/company/whatsapp.png"
                        className="h-8 w-8"
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <a
                        href="employer-detail.html"
                        className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500"
                      >
                        Whatsapp
                      </a>
                      <span className="block text-sm text-slate-400">
                        2 days ago
                      </span>
                    </div>
                  </div>
                  <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">
                    Remote
                  </span>
                </div>
                <div className="mt-6">
                  <a
                    href="job-detail-three.html"
                    className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500"
                  >
                    Php Developer
                  </a>
                  <h6 className="text-base font-medium">
                    <i className="uil uil-map-marker" /> Pakistan
                  </h6>
                </div>
                <div className="mt-6">
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-emerald-600 h-[6px] rounded-full"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="text-slate-400 text-sm">
                      <span className="text-slate-900 dark:text-white font-semibold inline-block">
                        21 applied
                      </span>
                      of 40 vacancy
                    </span>
                  </div>
                </div>
              </div>
              {/*end content*/}
            </div>
            {/*end grid*/}
            <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
              <div className="md:col-span-12 text-center">
                <nav aria-label="Page navigation example">
                  <ul className="inline-flex items-center -space-x-px">
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        1
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        2
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        aria-current="page"
                        className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600"
                      >
                        3
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        4
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        5
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                      >
                        <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/*end col*/}
            </div>
            {/*end grid*/}
          </div>
          {/*end col*/}
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
      <div className="container md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
            Here's why you'll love it Job Link
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Search all the open positions on the web. Get your own personalized
            salary estimate. Read reviews on over 30000+ companies worldwide.
          </p>
        </div>
        {/*end grid*/}
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
            <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
              <i className="uil uil-phone text-[30px] text-emerald-600 group-hover:text-white" />
            </div>
            <div className="mt-4">
              <a
                href="#"
                className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500"
              >
                24/7 Support
              </a>
              <p className="text-slate-400 mt-3 mb-2">
                Many desktop publishing now use and a search for job.
              </p>
              <a
                href="#"
                className="hover:text-emerald-600 font-medium transition-all duration-500"
              >
                Read More <i className="uil uil-arrow-right" />
              </a>
            </div>
          </div>
          {/*end content*/}
          <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
            <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
              <i className="uil uil-atom text-[30px] text-emerald-600 group-hover:text-white" />
            </div>
            <div className="mt-4">
              <a
                href="#"
                className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500"
              >
                Tech &amp; Startup Jobs
              </a>
              <p className="text-slate-400 mt-3 mb-2">
                Many desktop publishing now use and a search for job.
              </p>
              <a
                href="#"
                className="hover:text-emerald-600 font-medium transition-all duration-500"
              >
                Read More <i className="uil uil-arrow-right" />
              </a>
            </div>
          </div>
          {/*end content*/}
          <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
            <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
              <i className="uil uil-user-arrows text-[30px] text-emerald-600 group-hover:text-white" />
            </div>
            <div className="mt-4">
              <a
                href="#"
                className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500"
              >
                Quick &amp; Easy
              </a>
              <p className="text-slate-400 mt-3 mb-2">
                Many desktop publishing now use and a search for job.
              </p>
              <a
                href="#"
                className="hover:text-emerald-600 font-medium transition-all duration-500"
              >
                Read More <i className="uil uil-arrow-right" />
              </a>
            </div>
          </div>
          {/*end content*/}
          <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
            <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
              <i className="uil uil-hourglass text-[30px] text-emerald-600 group-hover:text-white" />
            </div>
            <div className="mt-4">
              <a
                href="#"
                className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500"
              >
                Save Time
              </a>
              <p className="text-slate-400 mt-3 mb-2">
                Many desktop publishing now use and a search for job.
              </p>
              <a
                href="#"
                className="hover:text-emerald-600 font-medium transition-all duration-500"
              >
                Read More <i className="uil uil-arrow-right" />
              </a>
            </div>
          </div>
          {/*end content*/}
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
      <div className="container-fluid md:mt-24 mt-16">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-8 md:col-span-7">
                  <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                    <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">
                      Explore a job now!
                    </h3>
                    <p className="text-slate-400 max-w-xl">
                      Search all the open positions on the web. Get your own
                      personalized salary estimate. Read reviews on over 30000+
                      companies worldwide.
                    </p>
                  </div>
                </div>
                {/*end col*/}
                <div className="lg:col-span-4 md:col-span-5">
                  <div className="ltr:text-right rtl:text-left relative z-1">
                    <a
                      href="employer-detail.html"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md"
                    >
                      Apply Now
                    </a>
                    <a
                      href="aboutus.html"
                      className="btn bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white rounded-md ms-2"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end grid*/}
              <div className="absolute -top-5 -start-5">
                <div className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45" />
              </div>
              <div className="absolute -bottom-5 -end-5">
                <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90" />
              </div>
            </div>
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
      </div>
      {/*end container*/}
    </section>
  );
};

export default Jobsection;
