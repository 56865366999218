/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative bg-slate-900 dark:bg-slate-800">
      <div className="container">
        <div className="grid grid-cols-1">
          <div className="relative py-12">
            {/* Subscribe */}
            <div className="relative w-full">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="md:col-span-3">
                  <Link
                    to="/"
                    className="flex justify-center md:justify-start focus:outline-none"
                  >
                    <img
                      src="/assets/images/Creative trigger-02 (1).png"
                      className=""
                      alt=""
                    />
                  </Link>
                </div>
                {/*end col*/}
                <div className="md:col-span-9">
                  <ul className="list-disc footer-list ltr:md:text-right rtl:md:text-left text-center space-x-3">
                    <li className="inline-block">
                      <Link
                        to="/"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium"
                      >
                        Home
                      </Link>
                    </li>

                    <li className="inline-block mt-[10px] md:mt-0">
                      <Link
                        to="contact"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                  {/*end icon*/}
                </div>
                {/*end col*/}
              </div>
              {/*end grid*/}
            </div>
            {/* Subscribe */}
          </div>
        </div>
      </div>
      {/*end container*/}
      <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
        <div className="container text-center">
          <div className="grid md:grid-cols-2 items-center gap-6">
            <div className="ltr:md:text-left rtl:md:text-right text-center">
              <p className="mb-0 text-gray-300 font-medium">
                © Job Link Zinad. Design with
                <i className="mdi mdi-heart text-red-600" /> by
                <a
                  href="https://hnhtechsolutions.com/"
                  target="_blank"
                  className="text-reset"
                  rel="noreferrer"
                >
                  HnhTechsolutions
                </a>
                .
              </p>
            </div>
            {/* <ul className="list-none ltr:md:text-right rtl:md:text-left text-center space-x-0.5">
              <li className="inline">
                <a
                  href="https://1.envato.market/jobstack"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i
                    className="uil uil-shopping-cart align-middle"
                    title="Buy Now"
                  />
                </a>
              </li>
              <li className="inline">
                <a
                  href="https://dribbble.com/shreethemes"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i
                    className="uil uil-dribbble align-middle"
                    title="dribbble"
                  />
                </a>
              </li>
              <li className="inline">
                <a
                  href="https://www.behance.net/shreethemes"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i className="uil uil-behance" title="Behance" />
                </a>
              </li>
              <li className="inline">
                <a
                  href="http://linkedin.com/company/shreethemes"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i className="uil uil-linkedin" title="Linkedin" />
                </a>
              </li>
              <li className="inline">
                <a
                  href="https://www.facebook.com/shreethemes"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i
                    className="uil uil-facebook-f align-middle"
                    title="facebook"
                  />
                </a>
              </li>
              <li className="inline">
                <a
                  href="https://www.instagram.com/shreethemes/"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i
                    className="uil uil-instagram align-middle"
                    title="instagram"
                  />
                </a>
              </li>
              <li className="inline">
                <a
                  href="https://twitter.com/shreethemes"
                  target="_blank"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                  rel="noreferrer"
                >
                  <i className="uil uil-twitter align-middle" title="twitter" />
                </a>
              </li>
              <li className="inline">
                <a
                  href="mailto:support@shreethemes.in"
                  className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"
                >
                  <i className="uil uil-envelope align-middle" title="email" />
                </a>
              </li>
            </ul> */}
            {/*end icon*/}
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
      </div>
    </footer>
  );
};

export default Footer;
