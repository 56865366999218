import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Job from "./pages/Job";
import JobApply from "./pages/JobApply";
import JobCategories from "./pages/JobCategories";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Profile from "./pages/auth/profile";
import NotFound from "./pages/notFound";
function App() {
  const httpLink = new HttpLink({ uri: "https://creative.dev-rr.xyz/graphql" });

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("authToken"); // Replace with your actual bearer token

    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Job" element={<Job />} />
          <Route path="/JobApply/:subCategoryId" element={<JobApply />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/JobCategories/:id" element={<JobCategories />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/Profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
