import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { LOGIN_USER } from "../../Graphql/Mutations";
import Loader from "../../components/Loader";
import { login as isLogin } from "../../slice/authslice";
import Layout from "../../layout/layout";
const Login = () => {
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  // UseMutation For Post in graphql
  const [login] = useMutation(LOGIN_USER);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setisLoading(true);
      login({ variables: values })
        .then((result) => {
          if (result && result.data) {
            // Access the response data here
            const responseData = result.data.login;
            dispatch(isLogin(responseData.user));
            // When the user logs in, store user data in localStorage
            localStorage.setItem("userData", JSON.stringify(responseData.user));
            localStorage.setItem("authToken", responseData.token);
            toast.success("Login Successfully");
            if (localStorage.getItem("success") === "true") {
              navigate("/auth/Profile");
              localStorage.removeItem("success");
            } else navigate(-1);
            setisLoading(false);
          } else {
            toast.error("SomeThing Went Wrong");
            setisLoading(false);
          }
        })
        .catch((errors) => {
          setisLoading(false);
          toast.error(errors + "");
          console.error(errors);
        });
    },
  });
  console.log(typeof localStorage.getItem("success"));
  return (
    <>
      {isLoading ? <Loader /> : null}
      <Layout>
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../..//assets/images/hero/bg3.html')] bg-no-repeat bg-center bg-cover">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black" />
          <div className="container">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
              <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                <div className="p-6">
                  <Link to="/">
                    <img
                      src="/assets/images/Creative trigger-01 (1).png"
                      className="mx-auto h-[50px] block dark:hidden"
                      alt=""
                    />
                    <img
                      src="/assets/images/Creative trigger-02 (1).png"
                      className="mx-auto h-[50px] dark:block hidden"
                      alt=""
                    />
                  </Link>
                  <h5 className="my-6 text-xl font-semibold">Login</h5>
                  <form onSubmit={formik.handleSubmit} className="text-left">
                    <div className="grid grid-cols-1">
                      <div className="mb-4 ltr:text-left rtl:text-right">
                        <label className="font-semibold" htmlFor="LoginEmail">
                          Email Address:
                        </label>
                        <input
                          name="email"
                          id="LoginEmail"
                          type="email"
                          className="form-input mt-3 rounded-md"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          placeholder="name@example.com"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-600">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 ltr:text-left rtl:text-right">
                        <label
                          className="font-semibold"
                          htmlFor="LoginPassword"
                        >
                          Password:
                        </label>
                        <input
                          id="LoginPassword"
                          type="password"
                          name="password"
                          className="form-input mt-3 rounded-md"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          placeholder="Password:"
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-red-600">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex justify-between mb-4">
                        <div className="inline-flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            defaultValue=""
                            id="RememberMe"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="RememberMe"
                          >
                            Remember me
                          </label>
                        </div>
                        {/* <p className="text-slate-400 mb-0">
                        <a
                          href="reset-password.html"
                          className="text-slate-400"
                        >
                          Forgot password ?
                        </a>
                      </p> */}
                      </div>
                      <div className="mb-4">
                        <input
                          type="submit"
                          className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                          defaultValue="Login / Sign in"
                        />
                      </div>
                      <div className="text-center">
                        <span className="text-slate-400 me-2">
                          Don't have an account ?
                        </span>{" "}
                        <Link
                          to="/auth/signup"
                          className="text-black dark:text-white font-bold"
                        >
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Login;
