import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import { ME } from "../Graphql/Queries";
import { useQuery } from "@apollo/client";

const JobApply = () => {
  const { refetch: refetchSubCategories } = useQuery(ME);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { subCategoryId } = useParams();
  let auth = localStorage.getItem("userData");
  auth = JSON.parse(auth);
  useEffect(() => {
    if (!auth) {
      toast.warning("Please Login First!");
      navigate("/auth/login");
    }
    if (auth?.phone === null) {
      toast.warning("Please Update Your Profile!");
      navigate("/auth/profile");
    }
  }, [auth, navigate]);
  const [field, setField] = useState({
    cv: undefined,
    subCategoryId: subCategoryId,
    country: null,
    city: null,
    address: null,
    dob: null,
  });
  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };
  const onImageUpload = (e) => {
    const file = e.target.files[0];
    setField({ ...field, cv: file });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (auth.cv || field.cv) {
      const formData = new FormData();
      const mutation = `mutation applyJob($subCategoryId: ID!, $cv: Upload,$country:String!,$city:String!,$address:String!,$dob:String!) {
        applyJob(subCategoryId: $subCategoryId,
           cv: $cv,
           country:$country,
           city:$city,
           address:$address,
           dob:$dob) {
          user {
            email
            first_name
            id
          }
        } 
      }`;
      const query = {
        query: mutation,
        variables: field,
      };
      formData.append("operations", JSON.stringify(query));
      formData.append("map", '{"0": ["variables.cv"]}');
      formData.append("0", field.cv);

      try {
        const response = await fetch("https://creative.dev-rr.xyz/graphql", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();

        console.log(responseData);
        if (responseData.data) {
          toast.success("Cv Submitted SuccessFully");
          navigate("/auth/profile");
          refetchSubCategories();
          setField({
            cv: undefined,
            subCategoryId: subCategoryId,
            country: null,
            city: null,
            address: null,
            dob: null,
          });
        } else {
          // toast.error(responseData?.errors?.[0]?.message);
          toast.error("Validation Failed")
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("Cv Failed To Upload!!");
    }
  };

  return (
    <Layout>
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.html')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90" />
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Job Link{" "}
            </h3>
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <a href="index.html">Job Link</a>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Job apply form
            </li>
          </ul>
        </div>
      </section>
      {/*end section*/}
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-slate-50 dark:text-slate-800">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      {/* End Hero */}
      {/* Start */}
      <section className="relative bg-slate-50 dark:bg-slate-800 lg:py-24 py-16">
        <div className="container">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 md:w-2/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                <form className="text-left" onSubmit={handleSubmit}>
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="RegisterName">
                      Your Name:<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="RegisterName"
                      value={`${auth?.first_name} ${auth?.last_name}`}
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      placeholder="Harry"
                    />
                  </div>
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Email Address:<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="LoginEmail"
                      type="email"
                      value={auth?.email}
                      className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      placeholder="name@example.com"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Phone Number:<span className="text-red-600">*</span>
                      </label>
                      <input
                        id="LoginEmail"
                        type="tel"
                        value={auth?.phone}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Date Of Brith:<span className="text-red-600">*</span>
                      </label>
                      <input
                        id="LoginEmail"
                        type="date"
                        onChange={onChange}
                        value={field?.dob}
                        name="dob"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Country:<span className="text-red-600">*</span>
                      </label>
                      <input
                        id="LoginEmail"
                        type="text"
                        onChange={onChange}
                        value={field?.country}
                        name="country"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        City:<span className="text-red-600">*</span>
                      </label>
                      <input
                        id="LoginEmail"
                        type="text"
                        onChange={onChange}
                        value={field?.city}
                        name="city"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      />
                    </div>
                  </div>
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Address:<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="LoginEmail"
                      type="text"
                      onChange={onChange}
                      value={field?.address}
                      name="address"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                    />
                  </div>
                  <div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      {auth?.cv && (
                        <>
                          <label
                            className="font-semibold"
                            htmlFor="multiple_files"
                          >
                            Uploaded Resume:
                          </label>
                          <div className="flex justify-center">
                            <iframe
                              src={auth?.cv}
                              title="Uploaded Resume"
                              width={500}
                              height={620}
                            />
                          </div>
                        </>
                      )}
                      <label className="font-semibold" htmlFor="multiple_files">
                        Upload New Resume:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="multiple_files"
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={onImageUpload}
                      />
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="btn w-50 rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white text-center w-[400px]	"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
                {/*end form*/}
              </div>
            </div>
          </div>
          {/*end flex*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
    </Layout>
  );
};

export default JobApply;
