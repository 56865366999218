import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { GET_SUB_CATEGORIES } from "../Graphql/Queries";
import Loader from "../components/Loader";
import Layout from "../layout/layout";

const JobCategories = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_SUB_CATEGORIES, {
    variables: { id },
  });
  console.log(data, id);
  return (
    <>
      {loading ? <Loader /> : null}
      <Layout>
        {/* Start Hero */}
        <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.html')] bg-top bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-emerald-900/90" />
          <div className="container">
            <div className="grid grid-cols-1 text-center mt-10">
              <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                Job Sub-Categories
              </h3>
            </div>
            {/*end grid*/}
          </div>
          {/*end container*/}
          <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
            <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
              <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Job Link</Link>
              </li>
              <li
                className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
                aria-current="page"
              >
                {data?.category?.name}
              </li>
            </ul>
          </div>
        </section>
        {/*end section*/}
        <div className="relative">
          <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
            <svg
              className="w-full h-auto"
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        {/* End Hero */}
        {/* Start */}
        <section className="relative md:py-24 py-16">
          {data?.category?.subCategories.length > 0 ? (
            data?.category?.subCategories.map((e) => (
              <div className="container-fluid md:mt-2 mt-5">
                <div className="container">
                  <div className="grid grid-cols-1">
                    <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
                      <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7">
                          <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                            <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">
                              {e.name}
                            </h3>
                            <p className="text-slate-400 max-w-xl">
                              {e.description}
                            </p>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="lg:col-span-4 md:col-span-5">
                          <div className="ltr:text-right rtl:text-left relative z-1">
                            <Link
                              to={`/JobApply/${e.id}`}
                              className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md"
                            >
                              Apply Now
                            </Link>
                          </div>
                        </div>
                        {/*end col*/}
                      </div>
                      {/*end grid*/}
                      <div className="absolute -top-5 -start-5">
                        <div className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45" />
                      </div>
                      <div className="absolute -bottom-5 -end-5">
                        <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90" />
                      </div>
                    </div>
                  </div>
                  {/*end grid*/}
                </div>
                {/*end container*/}
              </div>
            ))
          ) : (
            <p>No Job Found</p>
          )}

          {/*end container*/}
          <div className="container md:mt-24 md:pb-16 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-5 md:col-span-6">
                <div className="relative">
                  <div className="relative">
                    <img
                      src="/assets/images/about/ab01.jpg"
                      className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700"
                      alt=""
                    />
                  </div>
                  <div className="absolute md:-end-5 end-0 -bottom-16">
                    <img
                      src="/assets/images/about/ab02.jpg"
                      className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md shadow dark:shadow-gray-700"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                <div className="lg:ms-5">
                  <h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">
                    Frequently Asked Questions
                  </h3>
                  <p className="text-slate-400 max-w-xl">
                    Search all the open positions on the web. Get your own
                    personalized salary estimate. Read reviews on over 30000+
                    companies worldwide.
                  </p>
                  <div
                    id="accordion-collapseone"
                    data-accordion="collapse"
                    className="mt-8"
                  >
                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                      <h2
                        className="text-base font-semibold"
                        id="accordion-collapse-heading-1"
                      >
                        <button
                          type="button"
                          className="flex justify-between items-center p-5 w-full font-semibold text-left"
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>
                            {" "}
                            What support does Job Link offer for professionals
                            relocating to the Netherlands and Germany?
                          </span>
                          <svg
                            data-accordion-icon=""
                            className="w-4 h-4 rotate-180 shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </h2>
                      <div
                        id="accordion-collapse-body-1"
                        className="hidden"
                        aria-labelledby="accordion-collapse-heading-1"
                      >
                        <div className="p-5">
                          <p className="text-slate-400 dark:text-gray-400">
                            Job Link provides comprehensive support to
                            professionals relocating to the Netherlands and
                            Germany. Our services include aiding in finding
                            suitable accommodations, navigating government
                            agencies, and managing all necessary formalities and
                            registrations. We ensure a smooth transition for
                            individuals starting their careers or seeking new
                            opportunities in these countries.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                      <h2
                        className="text-base font-semibold"
                        id="accordion-collapse-heading-2"
                      >
                        <button
                          type="button"
                          className="flex justify-between items-center p-5 w-full font-semibold text-left"
                          data-accordion-target="#accordion-collapse-body-2"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-body-2"
                        >
                          <span>Who can benefit from Job Link's services?</span>
                          <svg
                            data-accordion-icon=""
                            className="w-4 h-4 shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </h2>
                      <div
                        id="accordion-collapse-body-2"
                        className="hidden"
                        aria-labelledby="accordion-collapse-heading-2"
                      >
                        <div className="p-5">
                          <p className="text-slate-400 dark:text-gray-400">
                            Job Link caters to graduates, academics, and
                            healthcare professionals from abroad seeking career
                            advancements or new opportunities in the Netherlands
                            and Germany. We welcome individuals eager to develop
                            their careers and face new challenges in these
                            vibrant environments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                      <h2
                        className="text-base font-semibold"
                        id="accordion-collapse-heading-3"
                      >
                        <button
                          type="button"
                          className="flex justify-between items-center p-5 w-full font-semibold text-left"
                          data-accordion-target="#accordion-collapse-body-3"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-body-3"
                        >
                          <span>
                            How can I access Job Link's support services?
                          </span>
                          <svg
                            data-accordion-icon=""
                            className="w-4 h-4 shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </h2>
                      <div
                        id="accordion-collapse-body-3"
                        className="hidden"
                        aria-labelledby="accordion-collapse-heading-3"
                      >
                        <div className="p-5">
                          <p className="text-slate-400 dark:text-gray-400">
                            Accessing our support is straightforward. You can
                            get in touch with us through our website or directly
                            contact our team. Express your needs, and we'll
                            provide tailored guidance to ensure a seamless start
                            in your new professional journey in the Netherlands
                            or Germany.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end grid*/}
          </div>
          {/*end container*/}

          {/*end container*/}
        </section>
        {/*end section*/}
        {/* End */}
      </Layout>
    </>
  );
};

export default JobCategories;
