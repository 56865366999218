import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../slice/authslice";

const Header = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  let auth = localStorage.getItem("userData");
  auth = JSON.parse(auth);
  console.log(auth);
  const dispatch = useDispatch();
  const toggleMenu = () => {
    var navigationElement = document.getElementById("navigation");
    if (navigationElement.style.display === "block") {
      navigationElement.style.display = "none";
    } else {
      navigationElement.style.display = "block";
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   const addScript = document.createElement("script");
  //   addScript.src =
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   document.body.appendChild(addScript);

  //   // Assign the function to the global scope
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  return (
    <nav id="topnav" className="defaultscroll is-sticky relative top-5 ">
      <div className="container">
        {/* Logo container */}
        <Link to="/" className="logo">
          <div className="block sm:hidden">
            <img
              src="/assets/images/Creative trigger-02 (1).png"
              className="h-10 inline-block dark:hidden"
              alt=""
            />
            <img
              src="/assets/images/Creative trigger-01 (1).png"
              className="h-10 hidden dark:inline-block"
              alt=""
            />
          </div>
          <div className="sm:block hidden">
            <span className="inline-block dark:hidden">
              <img
                src="/assets/images/Creative trigger-02 (1).png"
                className="h-[80px] l-dark"
                alt=""
              />
              <img
                src="/assets/images/Creative trigger-01 (1).png"
                className="h-[80px] l-light"
                alt=""
              />
            </span>
            <img
              src="/assets/images/Creative trigger-02 (1).png"
              className="h-[80px] hidden dark:inline-block"
              alt=""
            />
          </div>
        </Link>
        {/* End Logo container */}
        {/* Start Mobile Toggle */}
        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className="navbar-toggle"
              id="isToggle"
              onClick={toggleMenu} // Use onClick with a capital "C" for event handling
            >
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </Link>
          </div>
        </div>
        {/* End Mobile Toggle */}
        {/* Login button Start */}
        <ul className="buy-button list-none mb-0">
          {isAuthenticated ? (
            <Menu as="li" className="relative inline-block relative ps-1">
              <Menu.Button className="items-center block">
                <span className="btn btn-icon rounded-full border-emerald-600 hover:border-emerald-700 text-white">
                  <img
                    src={auth?.profile_image || `/assets/images/team/01.jpg`}
                    className="rounded-full"
                    alt=""
                  />
                </span>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 block">
                  <div className="py-1">
                    <Menu.Item as={"li"}>
                      <Link
                        to="/auth/Profile"
                        className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-user h-4 w-4 me-2"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        Profile
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      as={"li"}
                      className={
                        "border-t border-gray-100 dark:border-gray-800 my-2"
                      }
                    />
                    <Menu.Item as={"li"}>
                      <Link
                        onClick={handleLogout}
                        to="#"
                        className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-log-out h-4 w-4 me-2"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        Logout
                      </Link>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <li className="relative inline-block relative ">
              <Link to="/auth/login">Login</Link>
            </li>
          )}

          <li className="relative inline-block relative ps-2.5">
            <div id="google_translate_element" className="pt-3"></div>{" "}
          </li>

          {/* end dropdown */}
        </ul>
        {/* Login button End */}
        <div id="navigation">
          {/* Navigation Menu */}
          <ul className="navigation-menu justify-end nav-light">
            <li className={`parent-parent-menu-item `}>
              <Link to="/">Home</Link>
            </li>
            {/* <li className={`parent-parent-menu-item `}>
              <Link to="/Job">Jobs</Link>
            </li> */}

            <li>
              <Link to="/Contact" className="sub-menu-item">
                Contact
              </Link>
            </li>
          </ul>
          {/* end navigation menu */}
        </div>
        {/* end navigation */}
      </div>
      {/* end container */}
    </nav>
  );
};

export default Header;
