import React from "react";

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
