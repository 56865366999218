import { gql } from "@apollo/client";
export const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;
export const GET_SUB_CATEGORIES = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      subCategories {
        id
        name
        description
      }
    }
  }
`;
export const ME = gql`
  query me {
    me {
      id
      cv
      cover_image
      email
      first_name
      job_title
      last_name
      phone
      profile_image
      jobApplied {
        cv
        is_approved
        is_rejected
        subCategory {
          name
          description
          category {
            name
          }
        }
      }
    }
  }
`;
