import React from "react";
import Jobbanner from "../components/jobbanner";
import Jobsection from "../components/jobsection";
import Layout from "../layout/layout";

const Job = () => {
  return (
    <>
      <Layout>
        <Jobbanner />
        <Jobsection />
      </Layout>
    </>
  );
};

export default Job;
