import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CHANGE_PASSWORAD } from "../../Graphql/Mutations";
import { ME } from "../../Graphql/Queries";
import Loader from "../../components/Loader";
import Layout from "../../layout/layout";
import { login as isLogin } from "../../slice/authslice";

const Profile = () => {
  const dispatch = useDispatch();
  const { data, loading, refetch: refetchSubCategories } = useQuery(ME);
  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState({
    cover: "",
    profile: "",
    cvurl: "",
  });

  const [field, setField] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
    cv: "",
    profileImage: null,
    coverImage: null,
  });
  useEffect(() => {
    setField({
      firstName: data?.me?.first_name,
      lastName: data?.me?.last_name,
      email: data?.me?.email,
      phone: data?.me?.phone,
      jobTitle: data?.me?.job_title,
    });
  }, [data, loading]);
  const token = localStorage.getItem("authToken");
  const handleInputChange = (e) => {
    const { name, files, value } = e.target;

    if (name === "profileImage") {
      setField({ ...field, [name]: files[0] });
      setUrl({ ...url, profile: URL.createObjectURL(files[0]) });
    } else if (name === "coverImage") {
      // Update to match the name attribute in the input field
      setField({ ...field, [name]: files[0] });
      setUrl({ ...url, cover: URL.createObjectURL(files[0]) });
    } else if (name === "cv") {
      // Update to match the name attribute in the input field
      setField({ ...field, [name]: files[0] });
      setUrl({ ...url, cv: URL.createObjectURL(files[0]) });
    } else {
      setField({ ...field, [name]: value });
    }
  };

  const [passwordUpdate] = useMutation(CHANGE_PASSWORAD);
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    password: Yup.string()
      .min(8, "Must Contain 8 Characters")
      .required("New Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password Confirmation is required"),
  });
  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your form submission logic here
      passwordUpdate({ variables: values })
        .then((result) => {
          if (result && result.data) {
            // Access the response data here
            toast.success("Password Updated Successfully");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((errors) => {
          toast.error(errors + "");
        });
    },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    const mutation = `mutation profileUpdate(
        $firstName: String!,
        $lastName: String!,
        $email: String,
        $jobTitle: String,
        $phone:String!
        $profileImage: Upload,
        $coverImage: Upload,
        $cv:Upload
      ) {
        profileUpdate(
          first_name: $firstName,
          last_name: $lastName,
          email: $email,
          phone:$phone,
          job_title: $jobTitle,
          profile_image: $profileImage,
          cover_image: $coverImage,
          cv:$cv
        ) {
          id
          first_name
          last_name
          phone
          email
          job_title
          profile_image
          cover_image
          cv
        }
      }`;
    const query = {
      query: mutation,
      variables: {
        firstName: field.firstName,
        lastName: field.lastName,
        email: field.email,
        phone: field.phone,
        jobTitle: field.jobTitle,
      },
    };
    const { profileImage } = field;
    const { coverImage } = field;
    const { cv } = field;

    formData.append("operations", JSON.stringify(query));
    formData.append(
      "map",
      '{"0": ["variables.profileImage"], "1": ["variables.coverImage"],"2": ["variables.cv"]}'
    );
    formData.append("0", profileImage);
    formData.append("1", coverImage);
    formData.append("2", cv);

    try {
      const response = await fetch("https://creative.dev-rr.xyz/graphql", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      console.log(responseData);
      if (responseData.data) {
        toast.success("Profile Update");
        dispatch(isLogin(responseData.data.profileUpdate));
        localStorage.setItem(
          "userData",
          JSON.stringify(responseData.data.profileUpdate)
        );
        refetchSubCategories();
        setLoader(false);
      } else {
        toast.error(responseData?.errors?.[0]?.message);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error);
      setLoader(false);
    }
  };
  return (
    <>
      {loading || loader ? <Loader /> : null}
      <Layout>
        <section className="relative lg:mt-24 mt-[74px] pb-16">
          <div className="lg:container container-fluid">
            <div className="profile-banner relative text-transparent">
              <input
                id="pro-banner"
                name="coverImage"
                type="file"
                className="hidden"
                onChange={handleInputChange}
              />
              <div className="relative shrink-0">
                <img
                  src={
                    url.cover
                      ? url.cover
                      : data?.me?.cover_image
                      ? data?.me?.cover_image
                      : "/assets/images/hero/bg5.jpg"
                  }
                  className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                  id="profile-banner"
                  alt=""
                />
                <label
                  className="absolute inset-0 cursor-pointer"
                  htmlFor="pro-banner"
                />
              </div>
            </div>
            {/* <div class="relative shrink-0">
              <img src="/assets/images/hero/bg5.jpg" class="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700" alt="">
          </div> */}
            <div className="md:flex mx-4 -mt-12">
              <div className="md:w-full">
                <div className="relative flex items-end">
                  <div className="profile-pic text-center">
                    <input
                      id="pro-img"
                      name="profileImage"
                      type="file"
                      onChange={handleInputChange}
                      className="hidden"
                    />
                    <div>
                      <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                        <img
                          src={
                            url.profile
                              ? url.profile
                              : data?.me?.profile_image
                              ? data?.me?.profile_image
                              : "/assets/images/team/01.jpg"
                          }
                          className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                          id="profile-image"
                          alt=""
                          style={{ width: 112, height: 112 }}
                        />
                        <label
                          className="absolute inset-0 cursor-pointer"
                          htmlFor="pro-img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-4">
                    <h5 className="text-lg font-semibold">
                      {field.firstName} {field.lastName}
                    </h5>
                    <p className="text-slate-400">{field.jobTitle}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end */}
          <div className="container mt-16">
            <div className="grid grid-cols-1 gap-[30px]">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4">
                  Personal Detail :
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mb-2">
                    <div>
                      <label className="form-label font-medium">
                        First Name : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="First Name:"
                        id="firstName"
                        name="firstName"
                        onChange={handleInputChange}
                        defaultValue={field.firstName}
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Last Name : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Last Name:"
                        id="lastName"
                        name="lastName"
                        onChange={handleInputChange}
                        defaultValue={field.lastName}
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Your Email : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Email"
                        name="email"
                        onChange={handleInputChange}
                        defaultValue={field.email}
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Phone Number : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Phone Number"
                        name="phone"
                        onChange={handleInputChange}
                        defaultValue={field.phone}
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Job Title : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Job Title"
                        name="jobTitle"
                        onChange={handleInputChange}
                        defaultValue={field.jobTitle}
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Resume : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 p-1"
                        placeholder="Resume"
                        name="cv"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5"
                  >
                    Save Changes
                  </button>
                  {/*end grid*/}
                </form>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                  <div></div>
                  {/*end col*/}
                  <div>
                    <h5 className="text-lg font-semibold mb-4">
                      Change password :
                    </h5>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <label className="form-label font-medium">
                            Old password :
                          </label>
                          <input
                            type="password"
                            className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                            placeholder="Old password"
                            name="current_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.current_password}
                          />
                          {formik.touched.current_password &&
                          formik.errors.current_password ? (
                            <div className="text-red-600">
                              {formik.errors.current_password}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            New password :
                          </label>
                          <input
                            type="password"
                            className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                            placeholder="New password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-600">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Re-type New password :
                          </label>
                          <input
                            type="password"
                            className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                            placeholder="Re-type New password"
                            name="password_confirmation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password_confirmation}
                          />
                          {formik.touched.password_confirmation &&
                          formik.errors.password_confirmation ? (
                            <div className="text-red-600">
                              {formik.errors.password_confirmation}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/*end grid*/}
                      <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">
                        Save password
                      </button>
                    </form>
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
            </div>
          </div>
        </section>

        <div className="container md:mt-10 mt-5 mb-5">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
              Applied Jobs
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              Search all the open positions on the web. Get your own
              personalized salary estimate. Read reviews on over 30000+
              companies worldwide.
            </p>
          </div>
          {/*end grid*/}
          {data?.me?.jobApplied &&
            data?.me.jobApplied.map((e) => (
              <div className="lg:flex justify-center mt-8">
                <div className="lg:w-3/4">
                  <div className="group md:flex items-center justify-between p-6 rounded-lg shadow hover:shadow-lg dark:shadow-gray-700 transition-all duration-500">
                    <div>
                      <a
                        href="job-detail-one.html"
                        className="text-lg font-semibold hover:text-emerald-600"
                      >
                        {e?.subCategory?.category?.name}
                      </a>
                      <p className="text-slate-400 mt-1">
                        {e?.subCategory?.name}
                      </p>
                      <p className="text-slate-400 mt-1">
                        {e?.subCategory?.description}
                      </p>
                    </div>
                    {e?.is_approved && !e?.is_rejected ? (
                      <a
                        href="job-detail-one.html"
                        className="btn rounded-full bg-emerald-600 border border-slate-100 dark:border-slate-800 text-white md:mt-0 mt-4"
                      >
                        Approved
                      </a>
                    ) : !e?.is_approved && e?.is_rejected ? (
                      <a
                        href="job-detail-one.html"
                        className="btn rounded-full bg-red-600 border border-slate-100 dark:border-slate-800 text-white md:mt-0 mt-4"
                      >
                        Rejected
                      </a>
                    ) : (
                      <a
                        href="job-detail-one.html"
                        className="btn rounded-full bg-sky-600 border border-slate-100 dark:border-slate-800 text-white md:mt-0 mt-4"
                      >
                        Pending
                      </a>
                    )}
                  </div>
                  {/*end content*/}
                </div>
              </div>
            ))}

          {/*end*/}
        </div>
      </Layout>
    </>
  );
};

export default Profile;
