/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
const Homesection = ({ categories: data, loading }) => {
  console.log(data);
  const [searchTerm, setSearchTerm] = useState("");
  if (!data) {
    // Handle loading or error state
    return <Loader />;
  }
  const filteredCategories = data?.filter((category) =>
    category?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );
  const displayedCategories = filteredCategories?.slice(0, 8);
  return (
    <>
      {loading ? <Loader /> : null}

      <section className="relative md:py-24 py-16">
        {/* <div className="container">
          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <span className="counter-value" data-target={1010}>
                  1010
                </span>
                K+
              </h1>
              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Job Fulfillment
              </h5>
            </div>
        
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <span className="counter-value" data-target={25}>
                  2
                </span>
                +
              </h1>
              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Branches
              </h5>
            </div>
        
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <span className="counter-value" data-target={9}>
                  0
                </span>
                +
              </h1>
              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Years Experience
              </h5>
            </div>
          </div>
        </div> */}
        {/*end container*/}
        {/*end container*/}
        <div className="container ">
          <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
            <div className="lg:col-span-8 md:col-span-6 ltr:md:text-left rtl:md:text-right text-center">
              <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
                Browse by Categories
              </h3>
              <p className="text-slate-400 max-w-xl">
                Search your career opportunity with our categories
              </p>
            </div>
            <div className="lg:col-span-4 md:col-span-6 ltr:md:text-right rtl:md:text-left mt-8 md:mt-0">
              <div className="filter-search-form relative filter-border">
                <i className="uil-search icons" />
                <input
                  name="name"
                  type="text"
                  id="job-keyword"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                  placeholder="Search your Keywords"
                />
              </div>
            </div>
          </div>
          {/*end grid*/}
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {displayedCategories?.map((category) => (
              <div
                key={category.id}
                className="group p-4 rounded-md shadow dark:shadow-gray-700 bg-slate-50 dark:bg-slate-800 hover:bg-emerald-600 dark:hover:bg-emerald-600 transition-all duration-500"
              >
                <h5 className="text-lg font-semibold group-hover:text-white">
                  {category.name}
                </h5>

                <div className="mt-2">
                  <Link
                    to={`/JobCategories/${category.id}`}
                    className="text-emerald-600 dark:text-white/80 group-hover:text-white font-medium"
                  >
                    Explore Jobs <i className="uil uil-arrow-right" />
                  </Link>
                </div>
              </div>
            ))}

            {/*end content*/}
          </div>
          <div className="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-[30px]">
            <div className="lg:col-span-6">
              {" "}
              <h1 className="text-3xl font-semibold	">Our Mission</h1>
              <p className="mt-4 text-md">
                At Job Link, we're dedicated to empowering the professional
                aspirations of graduates, academics, and healthcare
                professionals seeking opportunities in the Netherlands and
                Germany. Our mission is simple yet ambitious: to invite
                individuals looking for new horizons, inviting them to join our
                team and collaboratively tackle future challenges. We offer
                comprehensive support throughout the relocation process,
                ensuring a seamless transition by assisting with accommodation,
                government agencies, and all necessary registrations. Our goal
                is not only to provide career opportunities but also to create
                an environment where your growth is nurtured, your skills are
                honed, and your contributions significantly impact the
                industries we operate in. Join us at Job Link and be a part of a
                community committed to fostering your professional journey while
                collectively shaping the future landscape of our fields.
              </p>
            </div>
            <div className="lg:col-span-6 flex justify-center h-40">
              <img src={"/assets/images/mission.png"} alt="Mission img" />
            </div>
          </div>
          <div className="grid lg:grid-cols-12 mt-20 md:grid-cols-2 gap-[30px] ">
            <div className="lg:col-span-6 flex justify-center h-40">
              <img src={"/assets/images/offer.png"} alt="Offer img" />
            </div>
            <div className="lg:col-span-6 ">
              {" "}
              <h1 className="text-3xl font-semibold	">What We Offer</h1>
              <p className="mt-4 text-md">
                We understand that moving to a new country can be both exciting
                and daunting. That's why we offer comprehensive support to our
                international recruits. From assisting in finding suitable
                accommodation to navigating governmental procedures and handling
                all necessary formalities and registrations, we are dedicated to
                ensuring a smooth transition for our new team members. Our
                support doesn't end there; we provide ongoing assistance to make
                settling into a new environment as seamless as possible.
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-12 mt-20 md:grid-cols-2 gap-[30px] ">
            <div className="lg:col-span-6">
              <h1 className="text-3xl font-semibold mt-7	">Join Us</h1>
              <h5 className="mt-5 text-md">
                If you're seeking an opportunity to grow professionally, work on
                impactful projects, and be a part of an innovative team, Job
                Link welcomes you. Explore a rewarding career where your skills
                and expertise contribute to shaping a brighter future.
              </h5>

              <p className="mt-2 text-md">
                Let us help you embark on this new chapter of your professional
                life in the Netherlands or Germany. Join us at Job Link, where
                your journey to making a difference begins.{" "}
              </p>
            </div>
            <div className="lg:col-span-6 flex justify-center h-40">
              <img src={"/assets/images/joinus.png"} alt="Offer img" />
            </div>
          </div>
          <div className="lg:grid-cols-12  mt-20 md:grid-cols-2 gap-[30px]">
            <h1 className="text-3xl font-semibold mt-7 text-center	">
              Your Journey with Job Link
            </h1>

            <p className="mt-5">
              Joining us means stepping into an environment dedicated to
              supporting your professional growth. Here's how it works:
            </p>

            <p className="mt-5">
              <b className="text-xl">Opportunity for Development:</b>Are you
              seeking new opportunities to advance your career? At JobLink , we
              offer an open invitation for driven individuals looking to evolve
              professionally within our collaborative environment.
            </p>

            <p className="mt-5">
              <b className="text-xl">Comprehensive Support:</b>We understand
              that relocating to a new country can be overwhelming. That's why
              we provide extensive assistance. From aiding in finding suitable
              accommodation to navigating governmental procedures, we're
              committed to ensuring your transition to the Netherlands or
              Germany is as seamless as possible.
            </p>

            <p className="mt-5">
              {" "}
              <b className="text-xl">Handling Formalities:</b>Dealing with
              government agencies and handling registrations can be intricate
              processes. Allow us to handle these formalities for you, so you
              can focus on settling into your new role.{" "}
            </p>

            <p className="mt-5">
              {" "}
              <b className="text-xl">Smooth Transition:</b>Starting anew in a
              different country comes with challenges. Job Link is here to
              facilitate a smooth start, supporting you every step of the way.{" "}
            </p>
          </div>
          <div>
            <h1 className="text-4xl font-semibold mt-20 text-center">
              Why Internship?{" "}
            </h1>{" "}
          </div>
          <div className="grid lg:grid-cols-12 mt-5 md:grid-cols-2 gap-[30px] ">
            <div className="lg:col-span-6">
              <h1 className="text-3xl font-semibold mt-7	">
                Internship Opportunities While Studying Abroad
              </h1>
              <h5 className="mt-5 text-md">
                At Job Link, we understand that pursuing studies abroad isn't
                just about academics; it's also about embracing the chance to
                make a meaningful impact through Internship. For students
                heading to the Netherlands or Germany, exploring Internship
                opportunities can be an enriching part of your educational
                journey.
              </h5>
            </div>
            <div className="lg:col-span-6 flex justify-center h-40">
              <img
                src={"/assets/images/StudyingAbroad.png"}
                className="w-2/4"
                alt="Offer img"
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-12 mt-20 md:grid-cols-2 gap-[30px] ">
            <div className="lg:col-span-6 flex justify-center h-40">
              <img src={"/assets/images/volunteers.png"} alt="Offer img" />
            </div>
            <div className="lg:col-span-6 ">
              {" "}
              <h1 className="text-3xl font-semibold	">
                Engaging in Internship work while studying abroad offers
                numerous advantages:
              </h1>
              <p className="mt-4 text-md">
                Cultural Immersion: Immerse yourself in the local community,
                gaining a deeper understanding of the host country's culture and
                society.
              </p>
              <p className="mt-4 text-md">
                Skill Development: Enhance your skill set in practical ways,
                contributing to both personal and professional growth.
              </p>
              <p className="mt-4 text-md">
                Networking and Relationships: Build valuable connections with
                locals and fellow Internships, expanding your network and
                cultural awareness.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Homesection;
