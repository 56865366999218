import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_USER } from "../../Graphql/Mutations";
import Loader from "../../components/Loader";
import Layout from "../../layout/layout";
const Signup = () => {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate("");
  // UseMutation For Post in graphql
  const [signup] = useMutation(CREATE_USER);
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setisLoading(true);
      signup({ variables: values })
        .then((result) => {
          if (result && result.data) {
            // Access the response data here
            toast.success("Signup Successfully");
            navigate("/auth/login");
            localStorage.setItem("success", true);
            setisLoading(false);
          } else {
            toast.error("SomeThing Went Wrong");
            setisLoading(false);
          }
        })
        .catch((errors) => {
          setisLoading(false);
          toast.error(errors + "");
          console.error(errors);
        });
    },
  });
  return (
    <>
      {isLoading ? <Loader /> : null}
      <Layout>
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.html')] bg-no-repeat bg-center bg-cover">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black" />
          <div className="container">
            <div className="grid lg:grid-cols-8 md:grid-cols-2 grid-cols-1">
              <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                <div className="p-6">
                  <Link to="/">
                    <img
                      src="/assets/images/logo-dark.png"
                      className="mx-auto h-[24px] block dark:hidden"
                      alt=""
                    />
                    <img
                      src="/assets/images/logo-light.png"
                      className="mx-auto h-[24px] dark:block hidden"
                      alt=""
                    />
                  </Link>
                  <h5 className="my-6 text-xl font-semibold">Signup</h5>
                  <form onSubmit={formik.handleSubmit} className="text-left">
                    <div className="grid grid-cols-1">
                      <div className="mb-4 ltr:text-left rtl:text-right flex space-x-4">
                        <div className="flex-1">
                          <label
                            className="font-semibold"
                            htmlFor="RegisterFirstName"
                          >
                            First Name:
                          </label>
                          <input
                            id="RegisterFirstName"
                            type="text"
                            name="first_name"
                            className="form-input mt-3 rounded-md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                            placeholder="Harry"
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="text-red-600">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex-1" style={{ marginLeft: "10px" }}>
                          <label
                            className="font-semibold"
                            htmlFor="RegisterLastName"
                          >
                            Last Name:
                          </label>
                          <input
                            id="RegisterLastName"
                            type="text"
                            name="last_name"
                            className="form-input mt-3 rounded-md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                            placeholder="Harry"
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="text-red-600">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mb-4 ltr:text-left rtl:text-right">
                        <label className="font-semibold" htmlFor="LoginEmail">
                          Email Address:
                        </label>
                        <input
                          name="email"
                          id="LoginEmail"
                          type="email"
                          className="form-input mt-3 rounded-md"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          placeholder="name@example.com"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-600">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 ltr:text-left rtl:text-right">
                        <label
                          className="font-semibold"
                          htmlFor="LoginPassword"
                        >
                          Password:
                        </label>
                        <input
                          id="LoginPassword"
                          type="password"
                          name="password"
                          className="form-input mt-3 rounded-md"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          placeholder="Password:"
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-red-600">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 ltr:text-left rtl:text-right">
                        <label
                          className="font-semibold"
                          htmlFor="LoginPassword"
                        >
                          Confirm Password:
                        </label>
                        <input
                          id="LoginPassword"
                          type="password"
                          name="password_confirmation"
                          className="form-input mt-3 rounded-md"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password_confirmation}
                          placeholder="Confirm Password:"
                        />
                        {formik.touched.password_confirmation &&
                        formik.errors.password_confirmation ? (
                          <div className="text-red-600">
                            {formik.errors.password_confirmation}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <button
                          type="submit"
                          className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                          disabled={isLoading} // Disable the button when loading
                        >
                          Signup
                        </button>
                      </div>
                      <div className="text-center">
                        <span className="text-slate-400 me-2">
                          Already have an account ?
                        </span>
                        <Link
                          to="/auth/login"
                          className="text-black dark:text-white font-bold"
                        >
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Signup;
